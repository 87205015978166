import {
  FORUM_AI_TRENDS,
  FORUM_MY_THREADS,
  FORUM_OVERVIEW,
  REPORTED_COMMENTS,
} from '@shared/utils';

const checkIsLinkActive = (
  pathname: string,
  itemUrl: string,
  isForumItem?: boolean
) => {
  if (isForumItem) {
    return (
      pathname === FORUM_AI_TRENDS ||
      pathname === FORUM_MY_THREADS ||
      pathname === REPORTED_COMMENTS ||
      pathname === FORUM_OVERVIEW
    );
  }

  if (itemUrl !== '/') {
    return pathname.includes(itemUrl);
  }
  return itemUrl === pathname;
};

export default checkIsLinkActive;
