import { useMemo } from 'react';

import { IUnreadNotificationsStatus } from '@interfaces/forum/notifications';
import { useQuery } from '@tanstack/react-query';

import { useSwitchForumApi } from '@api/forum/useSwitchForumApi';
import { userForumApi } from '@api/forum/user';

import { FORUM_KEYS } from '@shared/constants/queryKeys';

const useHasUnreadNotificationsQuery = () => {
  const forumApi = useSwitchForumApi(true);

  const role =
    forumApi === userForumApi
      ? FORUM_KEYS.roles.user
      : FORUM_KEYS.roles.publisher;

  const { data } = useQuery({
    queryKey: [FORUM_KEYS.notifications.hasNewNotifications, role],
    queryFn: () => forumApi.notifications.client.hasUnreadNotifications(),
    refetchInterval: 60_000,
  });

  return {
    unreadNotificationsStatus: useMemo(
      () => data?.data || ({} as IUnreadNotificationsStatus),
      [data?.data]
    ),
  };
};

export default useHasUnreadNotificationsQuery;
