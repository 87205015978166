import { Dispatch, SetStateAction } from 'react';

import { twMerge } from 'tailwind-merge';

import SubMenuSidebar from '@components/mobileSidebar/SubMenuSidebar';

import { FORUM_SIDEBAR_ITEMS } from '@features/forum/layout/constants';

import { ChevronRightIcon } from '@shared/assets';

import checkIsLinkActive from '../helpers';
import { INavItem } from '../types';
import CheckForForbiddenRoutesItem from './CheckForForbiddenRoutesItem';
import { NAVIGATION_ITEM_WITH_SUBMENU_SIDEBAR_CLASSES as CLASSES } from './constants';

interface IProps {
  item: INavItem;
  pathname: string;
  isSubMenuOpen: boolean;
  setIsSubMenuOpen: Dispatch<SetStateAction<boolean>>;
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
}

// We have this in a fragment so that the onClick doesn't pass through to the SubMenuSidebar. It's fixed anyway, so it doesn't need to be inside
const NavigationItemWithSubMenuSidebar = ({
  item,
  pathname,
  isSubMenuOpen,
  setIsSubMenuOpen,
  breakpoint = 'md',
}: IProps) => {
  const isActive = checkIsLinkActive(pathname, item.url, true);

  return (
    <>
      <div
        key={item.label}
        role="button"
        tabIndex={0}
        className={twMerge(
          'font-xlarge flex w-full shrink-0 items-center justify-between border-b border-neutral700 px-6 py-[26.5px] font-bold text-neutral300 hover:border-neutral400 hover:bg-neutral800',
          isActive &&
            `border-b border-brandPrimary text-white ${CLASSES.active[breakpoint]}`
        )}
        onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
      >
        <span className="uppercase">{item.label}</span>
        <ChevronRightIcon
          className={twMerge('size-4.5', CLASSES.chevron[breakpoint])}
        />
      </div>
      <SubMenuSidebar setIsOpen={setIsSubMenuOpen} isOpen={isSubMenuOpen}>
        {FORUM_SIDEBAR_ITEMS.map((item) => (
          <CheckForForbiddenRoutesItem
            key={item.id}
            item={item}
            pathname={pathname}
          />
        ))}
      </SubMenuSidebar>
    </>
  );
};

export default NavigationItemWithSubMenuSidebar;
