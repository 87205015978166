import { IGenericResponse } from '@interfaces';
import {
  IPresignedUrlRequest,
  IPresignedUrlResponse,
} from '@interfaces/fileResources';

import {
  withAuthenticatedClientRequest,
  withClientRequest,
} from '@api/requestBuilder/client/withClientRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import config from '@shared/config';

export const getPresignedUploadFile =
  (request: ICallableRequestBuilder<IGenericResponse<IPresignedUrlResponse>>) =>
  async (data: IPresignedUrlRequest) => {
    return request.call(
      `${config.userApiUrl}/api/v1/file-resources`,
      (init) => ({
        ...init,
        method: 'POST',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  };

export const getPresignedDownloadFile =
  (request: ICallableRequestBuilder<IGenericResponse<IPresignedUrlResponse>>) =>
  async (fileId: string) => {
    return request.call(
      `${config.userApiUrl}/api/v1/file-resources/${fileId}`,
      (init) => ({
        ...init,
        method: 'GET',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
      })
    );
  };

export const uploadFileToUrl =
  (request: ICallableRequestBuilder<IGenericResponse<IPresignedUrlResponse>>) =>
  async ({ file, url }: { file: File; url: string }) => {
    const formData = new FormData();
    formData.append('file', file);

    return request.call(url, (init) => ({
      ...init,
      method: 'PUT',
      headers: {
        ...init.headers,
        'Content-Type': 'multipart/form-data',
      },
      body: file,
    }));
  };

export const fileResourcesApi = {
  client: {
    getPresignedUploadFile: withAuthenticatedClientRequest(
      getPresignedUploadFile
    ),
    uploadFileToUrl: withClientRequest(uploadFileToUrl),
    getPresignedDownloadFile: withAuthenticatedClientRequest(
      getPresignedDownloadFile
    ),
  },
};
