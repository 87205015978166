export const BREAKPOINT_CLASSES = {
  regular: {
    sm: 'sm:flex sm:justify-center',
    md: 'md:flex md:justify-center',
    lg: 'lg:flex lg:justify-center',
    xl: 'xl:flex xl:justify-center',
    '2xl': '2xl:flex 2xl:justify-center',
  },
  small: {
    sm: 'sm:w-max sm:justify-start sm:rounded-br-sm sm:border-r',
    md: 'md:w-max md:justify-start md:rounded-br-md md:border-r',
    lg: 'lg:w-max lg:justify-start lg:rounded-br-lg lg:border-r',
    xl: 'xl:w-max xl:justify-start xl:rounded-br-xl xl:border-r',
    '2xl': '2xl:w-max 2xl:justify-start 2xl:rounded-br-2xl 2xl:border-r',
  },
};
