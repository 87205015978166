import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useSwitchForumApi } from '@api/forum/useSwitchForumApi';

import { FORUM_KEYS } from '@shared/constants/queryKeys';
import { toastError } from '@shared/utils';

const useReadAllNotificationsMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  const forumApi = useSwitchForumApi();

  return useMutation({
    mutationFn: () => forumApi.notifications.client.readAllNotifications(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FORUM_KEYS.notifications.notificationsList],
      });
      queryClient.invalidateQueries({
        queryKey: [FORUM_KEYS.notifications.hasNewNotifications],
      });

      onSuccess();
    },
    onError: (e) => toastError(e.message),
  });
};

export default useReadAllNotificationsMutation;
