import { useRouter } from 'next/navigation';
import { useCallback, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { watchAccount } from '@wagmi/core';
import { useAccount, useAccountEffect } from 'wagmi';

import { wagmiConfig } from '@shared/config/wagmiConfig';
import { PRODUCT_DETAILS, USER_ME } from '@shared/constants/queryKeys';
import {
  PRODUCTS,
  removeAccessTokenForCurrentUser,
  removeRefreshTokenForCurrentUser,
} from '@shared/utils';

const useWatchAccount = () => {
  const { address } = useAccount();
  const router = useRouter();
  const queryClient = useQueryClient();

  const logoutUser = useCallback(async () => {
    await removeAccessTokenForCurrentUser();
    await removeRefreshTokenForCurrentUser();

    queryClient.removeQueries({ queryKey: USER_ME });
    queryClient.invalidateQueries({ queryKey: [PRODUCTS] });
    queryClient.invalidateQueries({ queryKey: [PRODUCT_DETAILS] });

    router.refresh();
  }, [router, queryClient]);

  useAccountEffect({
    async onDisconnect() {
      await logoutUser();
    },
  });

  useEffect(() => {
    if (address) {
      const unwatch = watchAccount(wagmiConfig, {
        async onChange(data) {
          if (data.address?.toLowerCase() !== address?.toLowerCase()) {
            await logoutUser();
          }
        },
      });

      return () => {
        unwatch();
      };
    }
  }, [address, logoutUser]);
};

export default useWatchAccount;
