import Link from 'next/link';

import { INavItemProps } from '@app/publisher/types';
import { twMerge } from 'tailwind-merge';

const NavItem = ({
  href,
  Icon,
  label,
  iconProps,
  className,
}: INavItemProps) => {
  return (
    <Link
      className={twMerge(
        'group/navItem flex items-center p-6 font-medium text-neutral300 focus:bg-green900 focus:text-white hover:bg-green900 hover:text-white',
        className
      )}
      href={href}
    >
      <Icon
        className={twMerge(
          'text-current size-4 flex-shrink-0 group-hover/navItem:text-green500 group-focus/navItem:text-green500',
          iconProps
        )}
      />
      <span className="ml-2.5">{label}</span>
    </Link>
  );
};

export default NavItem;
