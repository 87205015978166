import NotificationMessage from './NotificationMessage';
import { NOTIFICATION_ACTION } from './constants';

export const getNotificationMessage = (
  notificationId: number,
  notificationType: string,
  username: string,
  threadTitle?: string,
  comment?: string
) => {
  switch (notificationType) {
    case NOTIFICATION_ACTION.LIKE:
      return comment ? (
        <NotificationMessage
          notificationId={notificationId}
          notificationDetail={username}
          text="liked your comment"
        />
      ) : (
        <NotificationMessage
          notificationId={notificationId}
          notificationDetail={username}
          text="liked your thread"
          threadTitle={threadTitle}
        />
      );
    case NOTIFICATION_ACTION.COMMENT:
    case NOTIFICATION_ACTION.REPLY:
      return (
        <NotificationMessage
          notificationId={notificationId}
          notificationDetail={username}
          text="replied to your thread"
          threadTitle={threadTitle}
        />
      );
    case NOTIFICATION_ACTION.REPLY:
      return (
        <NotificationMessage
          notificationId={notificationId}
          notificationDetail={username}
          text="replied to your comment"
        />
      );
    case NOTIFICATION_ACTION.DELETE:
      return comment ? (
        <NotificationMessage
          notificationId={notificationId}
          notificationDetail={comment}
          text="was removed because it violated the forum guidelines"
        />
      ) : (
        <NotificationMessage
          notificationId={notificationId}
          notificationDetail={threadTitle}
          text="was removed because it violated the forum guidelines"
        />
      );
    default:
      return;
  }
};
