import { Dispatch, SetStateAction } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useAccount, useSignMessage } from 'wagmi';

import useLoginWithWalletMutation from '@api/user/auth/mutations/login/useLoginWithWalletMutation';
import useRequestOtpCodeMutation from '@api/user/auth/mutations/login/useRequestOtpCodeMutation';
import { userAuthApi } from '@api/user/auth/userAuthApi';

import { MODAL_OPTIONS } from '@features/auth/constants';
import { walletErrorToast } from '@features/utils';
import { tryParseJsonString } from '@features/utils/strings';

import {
  IS_FORUM_LOGIN_IN_PROCESS,
  PRODUCTS,
  PRODUCT_DETAILS,
  USER_ME,
} from '@shared/constants/queryKeys';
import { toastError, toastSuccess } from '@shared/utils';
import {
  setAccessTokenForCurrentUser,
  setRefreshTokenForCurrentUser,
} from '@shared/utils/auth';

const useLoginWithWallet = (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,
  setActiveModal: Dispatch<SetStateAction<MODAL_OPTIONS>>,
  setRegistrationEmail: (registrationEmail: string) => void
) => {
  const queryClient = useQueryClient();
  const { address } = useAccount();

  const { mutate: requestOtpCode } = useRequestOtpCodeMutation({
    onSuccess: ({ data: { email } }) => {
      setRegistrationEmail(email);
      setActiveModal(MODAL_OPTIONS.OTP);
      toastSuccess('OTP code sent to your email');
    },
    onError: (e) => toastError(e.message),
  });

  const { mutate: loginWithWallet } = useLoginWithWalletMutation({
    onSuccess: async ({ data: { token, refreshToken } }) => {
      await setAccessTokenForCurrentUser(token);
      await setRefreshTokenForCurrentUser(refreshToken);
      toastSuccess('Login successful', { description: 'Start exploring' });

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: USER_ME }),
        queryClient.invalidateQueries({ queryKey: [PRODUCT_DETAILS] }),
        queryClient.invalidateQueries({ queryKey: [PRODUCTS] }),
        queryClient.setQueryData(IS_FORUM_LOGIN_IN_PROCESS, false),
      ]);

      setIsModalOpen(false);
      setIsLoading(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (e: any) => {
      if (e.message.toLowerCase().includes('email'))
        requestOtpCode({ cryptoWalletAddress: address });
      else {
        const parsedError = tryParseJsonString(e.message);
        if (parsedError) {
          toastError(parsedError.signInMessage);
        } else {
          toastError(e.message);
        }
      }

      setIsLoading(false);
    },
  });

  const { signMessageAsync } = useSignMessage();

  const handleLoginWithWallet = async () => {
    if (address) {
      setIsLoading(true);
      try {
        const {
          data: { signInMessage, registered },
        } = await userAuthApi.client.getSigninMessage({
          cryptoWalletAddress: address,
        });

        if (registered) {
          await signMessageAsync(
            { message: signInMessage },
            {
              onSuccess: (signature) =>
                loginWithWallet({
                  cryptoWalletAddress: address,
                  signInMessage: signInMessage,
                  signature,
                }),
            }
          );
        } else {
          setIsLoading(false);
          setActiveModal(MODAL_OPTIONS.SWITCH_ADDRESS);
        }
        /* eslint-disable @typescript-eslint/no-explicit-any */
      } catch (e: any) {
        if (e.toString().includes('Chain not configured.')) {
          toastError(
            'Please switch to Binance Smart Chain in your wallet and try again.'
          );
        } else if (e.cause) walletErrorToast(e.cause);
        else {
          toastError(e.message);
        }
        setIsLoading(false);
      }
    }
  };

  return handleLoginWithWallet;
};

export default useLoginWithWallet;
