import { UserAuthSteps } from '@interfaces/auth';

import Button from '@components/button';
import DividerFullWidth from '@components/dividerFullWidth';
import Modal from '@components/modals/Modal';

import { Party } from '@shared/assets';

interface ISuccessProps
  extends Omit<UserAuthSteps, 'isLoading' | 'setIsLoading'> {}

const Success = ({ isModalOpen, handleModalClose }: ISuccessProps) => {
  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={handleModalClose}
      className="!max-w-25.5rem"
    >
      <div className="flex flex-col gap-6">
        <div className="flex h-[80px] w-[80px] items-center justify-center rounded-bl-lg rounded-tr-lg border border-neutral700 bg-neutral900">
          <Party />
        </div>
        <p className="text-xl font-bold">
          Thank you for joining{' '}
          <span className="text-green500">AI TECH MARKETPLACE</span>
        </p>
        <p className="text-neutral200">
          To continue on exploring our marketplace, you need to verify your
          email.
        </p>
        <DividerFullWidth />
        <Button className="authButton" onClick={() => handleModalClose()}>
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export default Success;
