'use client';

import Link from 'next/link';

import { twMerge } from 'tailwind-merge';

import Button from '@components/button';

import { IEmptyTabData } from '@features/profile/tabs/types';

const EmptyContent = ({
  button,
  description,
  icon: Icon,
  title,
  className,
  onIconClick,
  customComponent,
}: IEmptyTabData) => {
  return (
    <div
      className={twMerge(
        'flexCenter min-h-80 flex-col gap-6 border border-neutral600 bg-neutral1000',
        className
      )}
    >
      <button
        type="button"
        onClick={onIconClick}
        className={twMerge(
          'roundedBlTr size-20 border border-neutral700 bg-neutral900 p-6',
          onIconClick ? 'cursor-pointer' : 'cursor-default'
        )}
      >
        <Icon className="size-8 h-8 w-8 text-neutral300" />
      </button>
      <div className="space-y-1 text-center">
        {title && <h5 className="font-large">{title}</h5>}
        <p className="font-medium text-neutral200">{description}</p>
      </div>
      {!!button && (
        <Link href={button.buttonLink}>
          <Button>{button.buttonText}</Button>
        </Link>
      )}
      {!!customComponent && customComponent}
    </div>
  );
};

export default EmptyContent;
