import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { UserAuthSteps } from '@interfaces/auth';
import { useQueryClient } from '@tanstack/react-query';
import { useAccount } from 'wagmi';

import useRequestOtpCodeMutation from '@api/user/auth/mutations/login/useRequestOtpCodeMutation';
import useRegisterConfirmEmailMutation from '@api/user/auth/mutations/register/useRegisterConfirmEmailMutation';

import OtpResendTimer from '@components/auth/otpResendTimer';
import Button from '@components/button';
import DividerFullWidth from '@components/dividerFullWidth';
import HookFormInput from '@components/form/hookFormInput';
import Modal from '@components/modals/Modal';

import { DEFAULT_ERROR_MESSAGE } from '@shared/constants/errors';
import { USER_ME } from '@shared/constants/queryKeys';
import { VerifyFormData, verifySchema } from '@shared/schemas';
import {
  setAccessTokenForCurrentUser,
  setRefreshTokenForCurrentUser,
  toastError,
  toastSuccess,
} from '@shared/utils';

interface IVerifyOtpProps extends UserAuthSteps {
  registrationEmail: string;
  onOtpVerify: () => void;
}

const RegisterVerifyOtpModal = ({
  isModalOpen,
  handleModalClose,
  registrationEmail,
  onOtpVerify,
  isLoading,
  setIsLoading,
}: IVerifyOtpProps) => {
  const methods = useForm<VerifyFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(verifySchema),
  });
  const queryClient = useQueryClient();
  const { address } = useAccount();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = methods;

  const { mutate: confirmEmail } = useRegisterConfirmEmailMutation({
    onSuccess: async ({ data: { refreshToken, token } }) => {
      setRefreshTokenForCurrentUser(refreshToken);
      setAccessTokenForCurrentUser(token);
      await queryClient.invalidateQueries({ queryKey: USER_ME });
      setIsLoading(false);
      onOtpVerify();
    },
    onError: (err) => {
      setIsLoading(false);
      toastError(err.message || DEFAULT_ERROR_MESSAGE);
    },
  });

  const { mutate: requestOtpCode } = useRequestOtpCodeMutation({
    onSuccess: () => {
      toastSuccess('OTP code sent to your email');
    },
    onError: (e) => toastError(e.message),
  });

  const modalDescription = (
    <div className="font-large flex flex-col gap-6 text-neutral200">
      <p>Verified by Email</p>
      <p>
        Please enter 6-digit OTP key we have sent you to your email address -{' '}
        <span className="text-white underline">{registrationEmail}</span>
      </p>
    </div>
  );

  const onSubmit: SubmitHandler<VerifyFormData> = async (values) => {
    setIsLoading(true);
    confirmEmail({
      otp: values.otp,
      cryptoWalletAddress: address,
    });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={handleModalClose}
      disableClose={isLoading}
      modalTitle="Please verify your email to continue"
      modalDescription={modalDescription}
      className="!max-w-25.5rem"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <HookFormInput
            label="OTP"
            id="otp"
            register={register('otp')}
            error={errors['otp']?.message}
            required
            type="number"
            autoResize
          />
          <DividerFullWidth className="my-6" />
          <div className="flex items-center justify-between">
            <OtpResendTimer
              handleResendOtp={() =>
                requestOtpCode({ cryptoWalletAddress: address })
              }
            />
            <Button className="authButton" disabled={isLoading}>
              Verify
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default RegisterVerifyOtpModal;
