export const MAIN_NAVIGATION_ITEM_CLASSES = {
  base: 'font-xlarge flex shrink-0 items-start border-b border-neutral700 px-6 font-bold uppercase text-neutral300 hover:border-neutral400 hover:bg-neutral800 lg:px-6',
  regular: {
    sm: 'sm:h-full sm:items-center sm:px-4 sm:label-large',
    md: 'md:h-full md:items-center md:px-4 md:label-large',
    lg: 'lg:h-full lg:items-center lg:px-6 lg:label-large',
    xl: 'xl:h-full xl:items-center xl:px-6 xl:label-large',
    '2xl': '2xl:h-full 2xl:items-center 2xl:px-6 2xl:label-large',
  },
  active: {
    sm: 'max-sm:bg-neutral800',
    md: 'max-md:bg-neutral800',
    lg: 'max-lg:bg-neutral800',
    xl: 'max-xl:bg-neutral800',
    '2xl': 'max-2xl:bg-neutral800',
  },
};

export const NAVIGATION_ITEM_WITH_SUBMENU_SIDEBAR_CLASSES = {
  active: {
    sm: 'max-sm:bg-neutral800',
    md: 'max-md:bg-neutral800',
    lg: 'max-lg:bg-neutral800',
    xl: 'max-xl:bg-neutral800',
    '2xl': 'max-2xl:bg-neutral800',
  },
  chevron: {
    sm: 'sm:hidden',
    md: 'md:hidden',
    lg: 'lg:hidden',
    xl: 'xl:hidden',
    '2xl': '2xl:hidden',
  },
};
