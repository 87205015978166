import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';

import { useAccount, useSignMessage } from 'wagmi';

import { useWithCorrectNetwork } from '@web3/hooks';

import useRegisterWithWalletMutation from '@api/user/auth/mutations/register/useRegisterWithWalletMutation';
import { userAuthApi } from '@api/user/auth/userAuthApi';

import { walletErrorToast } from '@features/utils';

import { toastError } from '@shared/utils';

import { RegisterFormData } from '../createAccountModal/registerFormSchema';

const useRegisterWithWallet = (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  onCreateAccount: () => void,
  setError: UseFormSetError<RegisterFormData>
) => {
  const { address } = useAccount();

  const { signMessageAsync } = useSignMessage();

  const { mutate: registerWithWallet } = useRegisterWithWalletMutation({
    onSuccess: () => {
      setIsLoading(false);
      onCreateAccount();
    },
    onError: (e) => {
      if (e.message.toLowerCase().includes('email'))
        setError('email', {
          type: 'manual',
          message: 'Email in use',
        });
      else if (e.message.toLowerCase().includes('username')) {
        setError('username', {
          type: 'manual',
          message: 'The username already exists, please try again',
        });
      } else {
        toastError(e.message);
      }

      setIsLoading(false);
    },
  });
  const handleRegisterWithWallet = async ({
    username,
    email,
    recaptchaToken,
  }: {
    username: string;
    email: string;
    recaptchaToken: string | null;
  }) => {
    if (address) {
      setIsLoading(true);
      try {
        const {
          data: { signInMessage },
        } = await userAuthApi.client.getSigninMessage({
          cryptoWalletAddress: address,
        });
        await signMessageAsync(
          { message: signInMessage },
          {
            onSuccess: (signature) => {
              if (address) {
                registerWithWallet({
                  cryptoWalletAddress: address,
                  signInMessage: signInMessage,
                  signature,
                  username,
                  email,
                  recaptchaToken,
                });
              }
            },
          }
        );
        /* eslint-disable @typescript-eslint/no-explicit-any */
      } catch (e: any) {
        if (e.cause) walletErrorToast(e.cause);
        else toastError(e.message);
        setIsLoading(false);
      }
    }
  };

  const handleRegisterWithCorrectNetwork = useWithCorrectNetwork(
    handleRegisterWithWallet
  );

  return handleRegisterWithCorrectNetwork;
};

export default useRegisterWithWallet;
