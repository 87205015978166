import {
  FlagIcon,
  ForumIcon,
  UserTalkingIcon,
  WiredGearIcon,
} from '@shared/assets';
import {
  FORUM,
  FORUM_AI_TRENDS,
  FORUM_MY_THREADS,
  FORUM_OVERVIEW,
  FORUM_REPORTED_COMMENTS,
} from '@shared/utils';

export type ForumSidebarItem = {
  id: string;
  link: string;
  Icon: React.ElementType;
  text: string;
};

export const FORUM_SIDEBAR_ITEMS: ForumSidebarItem[] = [
  {
    id: 'forum',
    link: FORUM_OVERVIEW,
    text: 'Forum',
    Icon: ForumIcon,
  },
  {
    id: 'myThreads',
    link: FORUM_MY_THREADS,
    text: 'My threads',
    Icon: UserTalkingIcon,
  },
  {
    id: 'reportedComments',
    link: FORUM_REPORTED_COMMENTS,
    text: 'Reported comments',
    Icon: FlagIcon,
  },
  {
    id: 'aiTrends',
    link: FORUM_AI_TRENDS,
    text: 'AI Trends',
    Icon: WiredGearIcon,
  },
];

export const LINKS_CONFIG = {
  mustRead: [{ text: 'Forum Terms and Conditions', url: FORUM }],
  featuredLinks: [
    {
      text: 'Solidus AITECH X',
      url: 'https://twitter.com/aitechio',
    },
    { text: 'Solidus AITECH Telegram', url: 'https://t.me/solidusaichat' },
  ],
};
