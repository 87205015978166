import { MouseEvent } from 'react';

import Button, { BUTTON_VARIANT } from '@components/button';

import useTimeCountdown from '@shared/hooks/useTimeCountdown';

interface IProps {
  handleResendOtp: () => void;
}

const OtpResendTimer = ({ handleResendOtp }: IProps) => {
  const { minutes, remainingSeconds: seconds, reset } = useTimeCountdown(300);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleResendOtp();
    reset();
  };

  return (
    <div className="font-small text-neutral200">
      {minutes + seconds !== 0 ? (
        <p>
          Resend available in{' '}
          <span>
            {minutes}:{seconds < 10 ? '0' : ''}
            {seconds}
          </span>
        </p>
      ) : (
        <Button
          type="button"
          onClick={handleClick}
          variant={BUTTON_VARIANT.LINK}
          className="pl-0"
        >
          Resend otp
        </Button>
      )}
    </div>
  );
};

export default OtpResendTimer;
