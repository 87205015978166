import { twMerge } from 'tailwind-merge';

import { ForumSidebarItem } from '@features/forum/layout/constants';

import useForumAuthStatus from '@shared/hooks/forum/useForumAuthStatus';
import {
  FORUM_AI_TRENDS,
  FORUM_MY_THREADS,
  FORUM_OVERVIEW,
  FORUM_REPORTED_COMMENTS,
} from '@shared/utils';

import checkIsLinkActive from '../helpers';
import NavItem from './NavItem';

interface IProps {
  item: ForumSidebarItem;
  pathname: string;
}

const CheckForForbiddenRoutesItem = ({ item, pathname }: IProps) => {
  const forumAuth = useForumAuthStatus();

  const isRouteAllowed = () => {
    switch (item.link) {
      case FORUM_OVERVIEW:
        return true;
      case FORUM_MY_THREADS:
      case FORUM_REPORTED_COMMENTS:
        return forumAuth.isLoggedIn;
      case FORUM_AI_TRENDS:
        return forumAuth.isPublisher;
      default:
        return false;
    }
  };

  if (!isRouteAllowed()) return null;

  return (
    <NavItem
      key={item.id}
      href={item.link}
      Icon={item.Icon}
      label={item.text}
      className={twMerge(
        checkIsLinkActive(pathname, item.link) && 'bg-green900 text-white'
      )}
      iconProps={twMerge('group-focus/navItem:text-white')}
    />
  );
};

export default CheckForForbiddenRoutesItem;
