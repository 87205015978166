'use client';

import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useDisconnect } from 'wagmi';

import GoogleCaptchaWrapper from '@components/googleCaptchaWrapper';

import { IS_FORUM_LOGIN_IN_PROCESS } from '@shared/constants/queryKeys';

import { MODAL_OPTIONS } from './constants';
import LoginModal from './login/loginModal';
import SwitchAddressModal from './login/switchAddressModal';
import CreateAccountModal from './register/createAccountModal';
import RegisterVerifyOtpModal from './register/registerVerifyOtpModal';

interface IProps {
  onSignUp: () => void;
}

const Auth = ({ onSignUp }: IProps) => {
  const { disconnect } = useDisconnect();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [activeModal, setActiveModal] = useState(MODAL_OPTIONS.LOGIN);
  const [registrationEmail, setRegistrationEmail] = useState('');

  const handleModalClose = async () => {
    setIsModalOpen(false);
    setActiveModal(MODAL_OPTIONS.LOGIN);
    if (activeModal !== MODAL_OPTIONS.CREATE_SUCCESS) {
      disconnect();
      queryClient.setQueryData(IS_FORUM_LOGIN_IN_PROCESS, false);
    }
  };

  switch (activeModal) {
    case MODAL_OPTIONS.LOGIN:
      return (
        <LoginModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          setIsModalOpen={setIsModalOpen}
          setActiveModal={setActiveModal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setRegistrationEmail={setRegistrationEmail}
        />
      );
    case MODAL_OPTIONS.SWITCH_ADDRESS:
      return (
        <SwitchAddressModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          onSwitchAddress={() => setActiveModal(MODAL_OPTIONS.LOGIN)}
          onSignUpClick={() => setActiveModal(MODAL_OPTIONS.CREATE_ACCOUNT)}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      );
    case MODAL_OPTIONS.CREATE_ACCOUNT:
      return (
        <GoogleCaptchaWrapper>
          <CreateAccountModal
            isModalOpen={isModalOpen}
            handleModalClose={handleModalClose}
            onCreateAccount={() => setActiveModal(MODAL_OPTIONS.OTP)}
            setRegistrationEmail={setRegistrationEmail}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </GoogleCaptchaWrapper>
      );
    case MODAL_OPTIONS.OTP:
      return (
        <RegisterVerifyOtpModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          registrationEmail={registrationEmail}
          onOtpVerify={() => onSignUp()}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      );
    default:
      null;
  }
};

export default Auth;
