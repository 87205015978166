import { ClipboardEvent, KeyboardEvent } from 'react';

const validateKeyPress = (e: KeyboardEvent<HTMLInputElement>, type: string) => {
  const isNumberInput = type === 'number';

  if (!isNumberInput) {
    return;
  }

  const charCode = e.keyCode || e.which;

  const isPrimaryDigitCode = charCode >= 48 && charCode <= 57;
  if (isPrimaryDigitCode) {
    return;
  }

  e.preventDefault();
};

const validatePaste = (e: ClipboardEvent<HTMLInputElement>, type: string) => {
  const isNumberInput = type === 'number';
  if (!isNumberInput) {
    return;
  }

  const text = e.clipboardData?.getData('text/plain');

  if (!text) return;

  const hasOnlyDigits = /^[0-9]*$/.test(text);
  if (isNumberInput && hasOnlyDigits) {
    return;
  }

  e.preventDefault();
};

export { validateKeyPress, validatePaste };
