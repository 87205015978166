import { LikeStatusListParams, ResponseWrapper } from '@interfaces';

import { withAuthenticatedClientRequest } from '@api/requestBuilder/client/withClientRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import { userForumRoutes } from './userForumApi.routes';

const updateLikeStatus =
  (request: ICallableRequestBuilder<ResponseWrapper>) =>
  async (data: LikeStatusListParams) => {
    return request.call(userForumRoutes.likeStatus, (init) => ({
      ...init,
      method: 'PUT',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }));
  };

export const userLikeStatusApi = {
  client: {
    updateLikeStatus: withAuthenticatedClientRequest(updateLikeStatus),
  },
};
