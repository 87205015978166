import { useState } from 'react';

import { INotificationParams } from '@interfaces/forum/notifications';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useSwitchForumApi } from '@api/forum/useSwitchForumApi';
import { userForumApi } from '@api/forum/user';

import { FORUM_KEYS } from '@shared/constants/queryKeys';

const DEFAULT_PAGE_NUMBER = 1;

const useGetNotificationsQuery = (params: INotificationParams) => {
  const forumApi = useSwitchForumApi(true);
  const [enableMe, setEnableMe] = useState(false);
  setTimeout(() => setEnableMe(true), 1000);

  const role =
    forumApi === userForumApi
      ? FORUM_KEYS.roles.user
      : FORUM_KEYS.roles.publisher;

  return useInfiniteQuery({
    initialPageParam: DEFAULT_PAGE_NUMBER,
    queryKey: [
      FORUM_KEYS.notifications.notificationsList,
      role,
      { limit: params.limit },
    ],
    enabled: enableMe,
    queryFn: ({ pageParam }) =>
      forumApi.notifications.client.getNotificationsList({
        ...params,
        page: pageParam.toString(),
      }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage + 1;
      return nextPage > lastPage.meta.totalPages ? null : nextPage;
    },
    refetchInterval: 60_000,
  });
};

export default useGetNotificationsQuery;
