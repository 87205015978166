import { PaginatedResponseWrapper } from '@interfaces';
import {
  IAiTrendingIdeas,
  IAiTrendingKeywords,
} from '@interfaces/forum/aiTrends';
import { IFetchParams } from '@interfaces/queryParams';

import { withAuthenticatedPublisherClientRequest } from '@api/requestBuilder/client/withAuthenticatedPublisherClientRequest';
import { withAuthenticatedPublisherServerRequest } from '@api/requestBuilder/server/withAuthenticatedPublisherServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import { generateUrlParams } from '@shared/utils/urlString';

import { publisherForumRoutes } from './publisherForumApi.routes';

const getKeywords =
  (
    request: ICallableRequestBuilder<
      PaginatedResponseWrapper<IAiTrendingKeywords[]>
    >
  ) =>
  async (params?: IFetchParams) => {
    const paramString = generateUrlParams(params);

    return request.call(
      `${publisherForumRoutes.aiTrends.trendingKeywords}?${paramString}`
    );
  };

const getIdeas =
  (
    request: ICallableRequestBuilder<
      PaginatedResponseWrapper<IAiTrendingIdeas[]>
    >
  ) =>
  async (params?: IFetchParams) => {
    const paramString = generateUrlParams(params);

    return request.call(
      `${publisherForumRoutes.aiTrends.trendingIdeas}?${paramString}`
    );
  };

export const publisherAiTrendsApi = {
  client: {
    getAiTrendingKeywords: withAuthenticatedPublisherClientRequest(getKeywords),
    getAiTrendingIdeas: withAuthenticatedPublisherClientRequest(getIdeas),
  },
  server: {
    getAiTrendingKeywords: withAuthenticatedPublisherServerRequest(getKeywords),
    getAiTrendingIdeas: withAuthenticatedPublisherServerRequest(getIdeas),
  },
};
