import Image from 'next/image';
import Link from 'next/link';
import { Dispatch, SetStateAction } from 'react';

import { INotifications } from '@interfaces/forum/notifications';
import { twMerge } from 'tailwind-merge';

import useReadSingleNotificationMutation from '@api/forum/mutations/notifications/useReadSingleNotificationMutation';

import { formatDateToHDMY } from '@features/utils';

import { DotIcon, ForumUserIcon } from '@shared/assets';
import { FORUM_THREAD } from '@shared/utils';

import { getNotificationMessage } from './getNotificationMessage';

interface IProps {
  notification: INotifications;
  setIsNotificationsListOpen: Dispatch<SetStateAction<boolean>>;
}

const NotificationItem = ({
  notification,
  setIsNotificationsListOpen,
}: IProps) => {
  const [time, date] = formatDateToHDMY(notification.createdAt).split(' ');

  const { mutateAsync: readSingleNotification } =
    useReadSingleNotificationMutation();

  const handleClick = () => {
    setIsNotificationsListOpen(false);
    if (!notification.isRead) readSingleNotification(notification.id);
  };

  return (
    <Link
      href={`${FORUM_THREAD}/${notification.threadId}`}
      onClick={handleClick}
      className={twMerge(
        'flex w-full cursor-pointer flex-col border-b border-neutral700 p-4',
        notification.isRead ? 'bg-neutral1000' : 'bg-neutral800'
      )}
    >
      <div className="flex items-center gap-4">
        <div className="relative flex h-10 min-h-10 w-10 min-w-10 items-center justify-center rounded-full bg-neutral700">
          {notification.actor.avatar ? (
            <Image
              src={notification.actor.avatar}
              alt="userProfileImage"
              className="rounded-full object-cover"
              layout="fill"
            />
          ) : (
            <ForumUserIcon />
          )}
          {!notification.isRead && (
            <div className="absolute left-10 top-0 h-[6px] w-[6px] rounded-full bg-green500" />
          )}
        </div>
        {getNotificationMessage(
          notification.id,
          notification.type,
          notification.actor.username,
          notification.thread?.title,
          notification.comment?.content
        )}
      </div>
      <span className="font-small mb-[14px] mt-2 flex items-center gap-1 pl-[56px] text-neutral400">
        {time}
        <DotIcon />
        {date}
      </span>
      {notification.comment && (
        <span className="line-clamp-2 max-h-10 min-h-10 pl-12 font-medium text-white">
          {`"${notification.comment.content}"`}
        </span>
      )}
    </Link>
  );
};

export default NotificationItem;
