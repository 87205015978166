import { ConfirmEmailData, ConfirmEmailResponse } from '@interfaces/auth';
import { useMutation } from '@tanstack/react-query';

import { userAuthApi } from '../../userAuthApi';

export interface MutationParams {
  onSuccess: (response: ConfirmEmailResponse) => void;
  onError?: (error: Error) => void;
}

const useRegisterConfirmEmailMutation = ({
  onSuccess,
  onError,
}: MutationParams) => {
  return useMutation({
    mutationFn: async (data: ConfirmEmailData) =>
      userAuthApi.client.confirmEmail(data),
    onSuccess,
    onError,
  });
};

export default useRegisterConfirmEmailMutation;
