import { Dispatch, SetStateAction, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { twMerge } from 'tailwind-merge';

import useReadAllNotificationsMutation from '@api/forum/mutations/notifications/useReadAllNotificationsMutation';
import useGetNotificationsQuery from '@api/forum/queries/notifications/useGetNotificationsQuery';

import Button, { BUTTON_VARIANT } from '@components/button';
import EmptyContent from '@components/emptyContent';
import { Loading } from '@components/loading';
import LoadingIcon from '@components/loading/LoadingIcon';

import { BellIcon } from '@shared/assets';

import NotificationItem from './NotificationsItem';

interface IProps {
  isNotificationsListOpen: boolean;
  setIsNotificationsListOpen: Dispatch<SetStateAction<boolean>>;
  unreadNotificationsStatus: boolean;
}

const Notifications = ({
  isNotificationsListOpen,
  setIsNotificationsListOpen,
  unreadNotificationsStatus,
}: IProps) => {
  const { data, hasNextPage, fetchNextPage, isPending } =
    useGetNotificationsQuery({ page: '1', limit: '5' });

  const notifications = useMemo(
    () => data?.pages.flatMap((page) => page.data),
    [data]
  );

  const renderNotifications = () => {
    if (isPending) {
      return <Loading className="mt-0" />;
    }

    if (!notifications?.length)
      return (
        <EmptyContent
          title="No notifications"
          className="border-0"
          icon={BellIcon}
        />
      );

    return notifications?.map((notification, index) => (
      <div className="flex w-full flex-col overflow-hidden" key={index}>
        <NotificationItem
          key={index}
          notification={notification}
          setIsNotificationsListOpen={setIsNotificationsListOpen}
        />
      </div>
    ));
  };

  const { mutateAsync: readAllNotifications } = useReadAllNotificationsMutation(
    () => {
      setIsNotificationsListOpen(false);
    }
  );

  const handleReadAllNotifications = async () => readAllNotifications();

  return (
    <div
      className={twMerge(
        'absolute right-4 top-[84px] z-20 hidden flex-col border border-neutral700 bg-neutral1000 sm:right-16',
        isNotificationsListOpen && 'flex'
      )}
    >
      <div className="flex items-center justify-between border-b border-neutral700 bg-neutral1000 p-4">
        <p className="font-medium">Notifications</p>
        <Button
          disabled={!unreadNotificationsStatus}
          variant={BUTTON_VARIANT.LINK}
          onClick={handleReadAllNotifications}
        >
          Mark all as read
        </Button>
      </div>
      <div
        id="notifications-container"
        className="green-scrollbar max-h-31.25rem w-18.75rem overflow-y-auto overscroll-contain sm:w-25rem"
      >
        <InfiniteScroll
          next={fetchNextPage}
          hasMore={hasNextPage}
          dataLength={notifications?.length || 0}
          loader={
            <div className="flexCenter bg-neutral1000 py-4">
              <LoadingIcon />
            </div>
          }
          scrollableTarget="notifications-container"
        >
          {renderNotifications()}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Notifications;
