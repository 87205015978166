import {
  PaginatedResponseWrapper,
  ReportedCommentResponseData,
  ReportsResponseData,
  ResponseWrapper,
} from '@interfaces';
import {
  GetCommentsListParams,
  ICommentBody,
  IGetCommentReplies,
  IReply,
  IReplyRequestBody,
  ISingleComment,
} from '@interfaces/forum/comment';
import { IFetchParams } from '@interfaces/queryParams';

import { withAuthenticatedClientRequest } from '@api/requestBuilder/client/withClientRequest';
import { withAuthenticatedServerRequest } from '@api/requestBuilder/server/withServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import { generateUrlParams } from '@shared/utils/urlString';

import { userForumRoutes } from './userForumApi.routes';

const getReportedComments =
  (request: ICallableRequestBuilder<ReportedCommentResponseData>) =>
  async (params?: IFetchParams) => {
    const paramString = generateUrlParams(params);

    return request.call(
      `${userForumRoutes.comments.reportedComment}?${paramString}`
    );
  };

const getReports =
  (request: ICallableRequestBuilder<ReportsResponseData>) =>
  async (commentId: number) => {
    return request.call(`${userForumRoutes.comments.reports}/${commentId}`);
  };

const deleteComment =
  (request: ICallableRequestBuilder<ResponseWrapper<null>>) =>
  (commentId: number): Promise<ResponseWrapper<null>> => {
    return request.call(
      `${userForumRoutes.comments.deleteComment}/${commentId}`,
      (init) => ({
        ...init,
        method: 'DELETE',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
      })
    );
  };

const deleteReport =
  (request: ICallableRequestBuilder<ResponseWrapper<null>>) =>
  (commentId: number): Promise<ResponseWrapper<null>> => {
    return request.call(
      `${userForumRoutes.comments.deleteReport}/${commentId}`,
      (init) => ({
        ...init,
        method: 'DELETE',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
      })
    );
  };

const createComment =
  (request: ICallableRequestBuilder<ICommentBody>) =>
  async (data: ICommentBody) => {
    return request.call(userForumRoutes.comments.createComment, (init) => ({
      ...init,
      method: 'POST',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }));
  };

const createReply =
  (request: ICallableRequestBuilder<IReplyRequestBody>) =>
  async (data: IReplyRequestBody) => {
    return request.call(userForumRoutes.comments.reply, (init) => ({
      ...init,
      method: 'POST',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }));
  };

const getComments =
  (
    request: ICallableRequestBuilder<PaginatedResponseWrapper<ISingleComment[]>>
  ) =>
  async (threadId: number, params: GetCommentsListParams) => {
    const paramString = generateUrlParams(params);

    return request.call(
      `${userForumRoutes.comments.getComments}/${threadId}?${paramString}`
    );
  };

const getReplies =
  (request: ICallableRequestBuilder<PaginatedResponseWrapper<IReply[]>>) =>
  async (commentId: number, params?: IGetCommentReplies) => {
    const paramString = generateUrlParams(params);

    return request.call(
      `${userForumRoutes.comments.getReplies}/${commentId}?${paramString}`
    );
  };

export const userCommentsApi = {
  client: {
    getReportedComments: withAuthenticatedClientRequest(getReportedComments),
    getReports: withAuthenticatedClientRequest(getReports),
    deleteComment: withAuthenticatedClientRequest(deleteComment),
    deleteReport: withAuthenticatedClientRequest(deleteReport),
    createComment: withAuthenticatedClientRequest(createComment),
    getComments: withAuthenticatedClientRequest(getComments),
    createReply: withAuthenticatedClientRequest(createReply),
    getReplies: withAuthenticatedClientRequest(getReplies),
  },
  server: {
    getReportedComments: withAuthenticatedServerRequest(getReportedComments),
    getReports: withAuthenticatedServerRequest(getReports),
    deleteComment: withAuthenticatedServerRequest(deleteComment),
    deleteReport: withAuthenticatedServerRequest(deleteReport),
    getComments: withAuthenticatedServerRequest(getComments),
    getReplies: withAuthenticatedServerRequest(getReplies),
  },
};
