import { PaginatedResponseWrapper, ResponseWrapper } from '@interfaces';
import {
  INotificationParams,
  INotifications,
} from '@interfaces/forum/notifications';

import { withAuthenticatedClientRequest } from '@api/requestBuilder/client/withClientRequest';
import { withAuthenticatedServerRequest } from '@api/requestBuilder/server/withServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import { generateUrlParams } from '@shared/utils/urlString';

import { userForumRoutes } from './userForumApi.routes';

const getNotificationsList =
  (
    request: ICallableRequestBuilder<PaginatedResponseWrapper<INotifications[]>>
  ) =>
  async (params: INotificationParams) => {
    const paramString = generateUrlParams(params);

    return await request.call(
      `${userForumRoutes.notifications.getNotificationsList}?${paramString}`
    );
  };

const hasUnreadNotifications =
  (request: ICallableRequestBuilder<ResponseWrapper>) => async () =>
    await request.call(
      `${userForumRoutes.notifications.hasUnreadNotifications}`
    );

const readAllNotifications =
  (request: ICallableRequestBuilder<ResponseWrapper>) => async () =>
    await request.call(
      `${userForumRoutes.notifications.readAllNotifications}`,
      (init) => ({
        ...init,
        method: 'PUT',
      })
    );

const readSingleNotification =
  (request: ICallableRequestBuilder<ResponseWrapper>) => async (id: number) =>
    await request.call(
      `${userForumRoutes.notifications.readSingleNotification}/${id}`,
      (init) => ({
        ...init,
        method: 'PUT',
      })
    );

export const userNotificationsApi = {
  client: {
    getNotificationsList: withAuthenticatedClientRequest(getNotificationsList),
    readAllNotifications: withAuthenticatedClientRequest(readAllNotifications),
    readSingleNotification: withAuthenticatedClientRequest(
      readSingleNotification
    ),
    hasUnreadNotifications: withAuthenticatedClientRequest(
      hasUnreadNotifications
    ),
  },
  server: {
    getNotificationsList: withAuthenticatedServerRequest(getNotificationsList),
    hasUnreadNotifications: withAuthenticatedServerRequest(
      hasUnreadNotifications
    ),
  },
};
