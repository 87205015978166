import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useReCaptchaToken = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getReCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      return null;
    }

    try {
      const token = await executeRecaptcha();
      return token;
    } catch (error) {
      return null;
    }
  }, [executeRecaptcha]);

  return getReCaptchaToken;
};

export default useReCaptchaToken;
