import { Route } from 'next';
import Link from 'next/link';

import { twMerge } from 'tailwind-merge';

import checkIsLinkActive from '../helpers';
import { INavItem } from '../types';
import { MAIN_NAVIGATION_ITEM_CLASSES } from './constants';

interface IProps {
  item: INavItem;
  isSmallNavigation?: boolean;
  pathname: string;
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
}

const MainNavigationItem = ({
  item,
  isSmallNavigation,
  pathname,
  breakpoint = 'md',
}: IProps) => {
  const isActive = checkIsLinkActive(pathname, item.url);

  return (
    <Link
      key={item.label}
      href={item.url as Route}
      className={twMerge(
        MAIN_NAVIGATION_ITEM_CLASSES.base,
        MAIN_NAVIGATION_ITEM_CLASSES.regular[breakpoint],
        isSmallNavigation ? 'grow' : 'py-[26.5px]',
        isActive &&
          `pointer-events-none border-b border-brandPrimary text-white ${MAIN_NAVIGATION_ITEM_CLASSES.active[breakpoint]}`
      )}
    >
      {item.label}
    </Link>
  );
};

export default MainNavigationItem;
