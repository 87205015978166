'use client';

import {
  ChangeEvent,
  Dispatch,
  InputHTMLAttributes,
  ReactNode,
  SetStateAction,
  useRef,
} from 'react';
import { UseFormRegisterReturn, useFormContext } from 'react-hook-form';

import { twMerge } from 'tailwind-merge';

import AutoResizableInput from '@components/inputs/AutoResizableInput';
import { IAutoResizableInputProps } from '@components/inputs/types';

import InputWrapper from '../inputWrapper';
import NumericFormInput from '../numericFormInput';
import { INumberInputProps } from '../numericFormInput/types';
import { DEFAULT_INPUT_MIN_WIDTH } from './constants';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  innerContainerClassName?: string;
  hiddenSpanClassName?: string;
  inputClassName?: string;
  register: Partial<UseFormRegisterReturn>;
  label?: string | ReactNode;
  inline?: boolean;
  id: string;
  error?: string;
  autoResize?: boolean;
  children?: ReactNode;
  splitLine?: boolean;
  minWidth?: number;
  hideIsOptional?: boolean;
  labelClassName?: string;
  setFocused?: Dispatch<SetStateAction<string>>;
  tooltip?: string;
  readonly?: boolean;
  trimValue?: boolean;
  shouldValidate?: boolean;
  errorClassName?: string;
  numberInputProps?: INumberInputProps;
  optionalDescriptionElement?: ReactNode;
}

const HookFormInput = ({
  register,
  inline,
  id,
  label,
  required,
  error,
  className,
  innerContainerClassName,
  hiddenSpanClassName,
  inputClassName,
  type = 'text',
  autoResize,
  children,
  splitLine,
  minWidth = DEFAULT_INPUT_MIN_WIDTH,
  hideIsOptional = false,
  labelClassName,
  setFocused,
  tooltip,
  onChange,
  readonly,
  trimValue,
  shouldValidate,
  errorClassName,
  maxLength,
  numberInputProps,
  optionalDescriptionElement,
  ...attributes
}: IProps) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const value = watch(id);
  const isNumberInput = type === 'number';

  const divContainerRef = useRef<HTMLDivElement>(null);

  const defaultOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    // So that the proper value is set in the form
    if (isNumberInput && numberInputProps?.onNumberChange) return;

    const value = trimValue ? e.target.value.trim() : e.target.value;
    setValue(id, value, { shouldDirty: true, shouldValidate });
  };

  const sharedInputProps: IAutoResizableInputProps = {
    id,
    value,
    autoResize,
    type,
    containerClassName: twMerge(
      'relative w-fit max-w-full overflow-hidden',
      innerContainerClassName
    ),
    inputClassName,
    errorMessage: error,
    hiddenSpanClassName,
    minWidth,
    inputAttributes: {
      'aria-invalid': !!error,
      ...attributes,
    },
    containerRef: divContainerRef,
    isFormInput: true,
    setFocused,
    onChange: onChange || defaultOnChange,
    readonly,
    maxLength,
    children,
  };

  return (
    <InputWrapper
      label={label}
      id={id}
      inline={inline}
      error={error || (errors[id]?.message as string)}
      required={required}
      className={className}
      containerProps={{
        ref: divContainerRef,
      }}
      hideIsOptional={hideIsOptional}
      splitLine={splitLine}
      labelClassName={labelClassName}
      tooltip={tooltip}
      errorClassName={errorClassName}
      optionalDescriptionElement={optionalDescriptionElement}
    >
      {isNumberInput ? (
        <NumericFormInput
          {...sharedInputProps}
          numberInputProps={numberInputProps}
        />
      ) : (
        <AutoResizableInput {...sharedInputProps} register={register} />
      )}
    </InputWrapper>
  );
};

export default HookFormInput;
