import { useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import { DEFAULT_INPUT_MIN_WIDTH } from '@components/form/hookFormInput/constants';
import useAutoResize from '@components/form/hooks/useAutoResize';
import InputStyle from '@components/form/styles/inputStyle';

import { IAutoResizableInputProps } from './types';
import { validateKeyPress, validatePaste } from './validation';

const AutoResizableInput = ({
  value,
  id,
  autoResize = true,
  errorMessage,
  minWidth = DEFAULT_INPUT_MIN_WIDTH,
  containerClassName,
  inputAttributes,
  type = 'text',
  hiddenSpanClassName,
  children,
  containerRef,
  isFormInput,
  readonly,
  onChange,
  autoComplete,
  inputClassName,
  disabled,
  setFocused,
  onClick,
  maxLength,
  register,
  controlField,
}: IAutoResizableInputProps) => {
  const spanAutoResizeRef = useRef<HTMLSpanElement>(null);

  const getInputStyle = useAutoResize({
    value: String(value),
    spanAutoResizeRef,
    divContainerRef: containerRef,
    minWidth,
    autoResize,
  });

  // If controlField is provided it means it is controlled by react-hook-form Controller (react-number-format)
  const parsedValue = isFormInput && !controlField ? undefined : value;

  return (
    <div className={containerClassName}>
      <input
        id={id}
        disabled={disabled}
        type={type}
        aria-invalid={!!errorMessage}
        className={twMerge(
          InputStyle(errorMessage, readonly, type),
          type === 'number' ? 'hide-input-arrows' : '',
          inputClassName
        )}
        style={getInputStyle()}
        readOnly={readonly}
        {...controlField}
        value={parsedValue}
        {...inputAttributes}
        {...register}
        onChange={(e) => {
          onChange?.(e);
          controlField?.onChange?.(e);
        }}
        autoComplete={autoComplete}
        onFocus={() => setFocused && setFocused(id)}
        onBlur={() => {
          setFocused && setFocused('');
          controlField?.onBlur?.();
        }}
        onClick={onClick}
        maxLength={maxLength}
        onKeyPress={(e) => validateKeyPress(e, type)}
        onPaste={(e) => validatePaste(e, type)}
        onKeyDown={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
      />
      {autoResize && (
        <span
          className={twMerge(
            'font-xsmall invisible fixed block px-1.5 pl-3 font-bold',
            hiddenSpanClassName
          )}
          ref={spanAutoResizeRef}
        >
          {value}
        </span>
      )}
      {children}
    </div>
  );
};

export default AutoResizableInput;
