import { UserAuthSteps } from '@interfaces/auth';
import { useAccount, useConnect, useDisconnect } from 'wagmi';

import Modal from '@components/modals/Modal';
import ModalFooter from '@components/modals/ModalFooter';

import { walletErrorToast } from '@features/utils';

import { CopyIcon, ErrorIcon } from '@shared/assets';
import { copyToClipboard } from '@shared/utils';

interface ISwitchAddressProps extends UserAuthSteps {
  onSwitchAddress: () => void;
  onSignUpClick: () => void;
}

const SwitchAddress = ({
  isModalOpen,
  handleModalClose,
  onSwitchAddress,
  onSignUpClick,
  isLoading,
  setIsLoading,
}: ISwitchAddressProps) => {
  const { address, connector } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const { connect } = useConnect({
    mutation: {
      onSuccess: () => {
        setIsLoading(false);
        onSwitchAddress();
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      onError: (e: any) => {
        walletErrorToast(e.cause);
        setIsLoading(false);
      },
    },
  });

  const handleSwitchAddress = async () => {
    setIsLoading(true);
    await disconnectAsync({ connector });
    connector && connect({ connector });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={handleModalClose}
      disableClose={isLoading}
      modalTitle="Account doesn't exist"
      modalDescription="No account linked to this address"
      className="!max-w-25.5rem"
    >
      <div className="font-xsmall flex w-full flex-col gap-2 border border-neutral500 px-2.5 py-3 font-bold">
        <div className="uppercase">Wallet address</div>
        <div className="flex justify-between">
          <div className="w-max max-w-full overflow-hidden overflow-ellipsis bg-red900 px-1.5 py-1 text-white">
            {address}
          </div>
          {!!address && (
            <button onClick={() => copyToClipboard(address)}>
              <CopyIcon className="size-3.5 text-neutral300" />
            </button>
          )}
        </div>
        <div className="mt-2 flex items-center gap-1 font-normal text-red500">
          <ErrorIcon className="size-3" />
          <p className="font-xsmall">No account linked to this address</p>
        </div>
      </div>
      <ModalFooter
        customCancelButtonElement={
          <div className="flex flex-col">
            <p className="text-neutral-400 font-small">No AI TECH account?</p>
            <p
              className="font-xsmall cursor-pointer font-bold uppercase text-yellow500"
              onClick={() => onSignUpClick()}
            >
              Sign up
            </p>
          </div>
        }
        confirmButtonProps={{
          text: 'Switch address',
          onClick: handleSwitchAddress,
        }}
      />
    </Modal>
  );
};

export default SwitchAddress;
