import { useEffect, useState } from 'react';

const useTimeCountdown = (timeInSeconds: number) => {
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [seconds, setSeconds] = useState(timeInSeconds);

  useEffect(() => {
    setEndTime(new Date(Date.now() + timeInSeconds * 1000));
  }, [timeInSeconds]);

  useEffect(() => {
    if (!endTime) return;

    const intervalId = setInterval(() => {
      const remainingTime = Math.round((endTime.getTime() - Date.now()) / 1000);

      if (remainingTime >= 0) {
        setSeconds(remainingTime);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime]);

  const reset = () => {
    setEndTime(new Date(Date.now() + timeInSeconds * 1000));
    setSeconds(timeInSeconds);
  };

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return { minutes, remainingSeconds, reset };
};

export default useTimeCountdown;
