import { Dispatch, SetStateAction } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { UserAuthSteps } from '@interfaces/auth';
import { useAccount } from 'wagmi';

import Button from '@components/button';
import DividerFullWidth from '@components/dividerFullWidth';
import HookFormInput from '@components/form/hookFormInput';
import Modal from '@components/modals/Modal';

import { MAX_EMAIL_LENGTH, MAX_USERNAME_LENGTH } from '@shared/constants';
import useReCaptchaToken from '@shared/hooks/useReCaptchaToken';

import useRegisterWithWallet from '../hooks/useRegisterWithWallet';
import { RegisterFormData, registerSchema } from './registerFormSchema';

interface ICreateAccountProps extends UserAuthSteps {
  onCreateAccount: () => void;
  setRegistrationEmail: Dispatch<SetStateAction<string>>;
}

const CreateAccount = ({
  isModalOpen,
  handleModalClose,
  onCreateAccount,
  setRegistrationEmail,
  isLoading,
  setIsLoading,
}: ICreateAccountProps) => {
  const { address } = useAccount();

  const getReCaptchaToken = useReCaptchaToken();

  const methods = useForm<RegisterFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(registerSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
  } = methods;

  const registerWithWallet = useRegisterWithWallet(
    setIsLoading,
    onCreateAccount,
    setError
  );

  const onSubmit: SubmitHandler<RegisterFormData> = async (values) => {
    setIsLoading(true);
    const gReCaptchaToken = await getReCaptchaToken();
    await registerWithWallet({
      username: values.username,
      email: values.email,
      recaptchaToken: gReCaptchaToken,
    });
    setRegistrationEmail(values.email);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={handleModalClose}
      disableClose={isLoading}
      modalTitle="Create account"
      className="!max-w-25.5rem"
    >
      <div className="font-xsmall flex w-full flex-col gap-2 border border-neutral500 px-2.5 py-3 font-bold">
        <div className="uppercase">Wallet address</div>
        <div className="w-max bg-neutral700 px-1.5 py-1 text-white">
          {address}
        </div>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <HookFormInput
            label="USERNAME"
            id="username"
            register={register('username')}
            error={errors['username']?.message}
            required
            type="text"
            className="mt-4"
            autoResize
            maxLength={MAX_USERNAME_LENGTH}
          />
          <HookFormInput
            label="EMAIL"
            id="email"
            register={register('email')}
            error={errors['email']?.message}
            required
            type="text"
            className="mt-4"
            autoResize
            maxLength={MAX_EMAIL_LENGTH}
          />
          <DividerFullWidth className="my-6" />
          <Button className="authButton" disabled={isLoading}>
            Create Account
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CreateAccount;
