import { IFetchParams } from '@interfaces/queryParams';

export function generateRandomKey(prefix = '') {
  return `${prefix}_${Math.random().toString(36).substr(2, 9)}`;
}

export const getPaginationItems = (
  currentPage: number,
  totalPages: number,
  siblingCount = 1
): (number | string)[] => {
  const totalPageNumbers = siblingCount + 5;

  if (totalPages <= totalPageNumbers) {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
  const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);

  const showLeftDots = leftSiblingIndex > 2;
  const showRightDots = rightSiblingIndex < totalPages - 2;

  const firstPageIndex = 1;
  const lastPageIndex = totalPages;

  if (!showLeftDots && showRightDots) {
    const leftRange = Array.from(
      { length: 3 + 2 * siblingCount },
      (_, index) => index + 1
    );
    return [...leftRange, '...', totalPages];
  }

  if (showLeftDots && !showRightDots) {
    const rightRange = Array.from(
      { length: 3 + 2 * siblingCount },
      (_, index) => totalPages - (3 + 2 * siblingCount - 1) + index
    );
    return [firstPageIndex, '...', ...rightRange];
  }

  if (showLeftDots && showRightDots) {
    const middleRange = Array.from(
      { length: 1 + 2 * siblingCount },
      (_, index) => leftSiblingIndex + index
    );
    return [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
  }

  return [];
};

export const getSortQueryParam = (fetchParams?: IFetchParams) => {
  if (!fetchParams?.sortBy) {
    return {};
  }
  return { sortBy: `${fetchParams.sortBy}:${fetchParams.sortDirection}` };
};
