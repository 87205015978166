import { ReactNode, useEffect, useRef, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { generateRandomKey } from '@components/table/utils';
import CustomTooltip from '@components/tooltip/CustomTooltip';

import { InfoIcon } from '@shared/assets';
import { useDebounce } from '@shared/hooks';
import { cutOffString } from '@shared/utils';

const MD_SCREEN_WIDTH = 768;

interface IProps {
  id?: string;
  title: string;
  className?: string;
  children?: ReactNode;
  hideIcon?: boolean;
  maxCharacters?: number; // This only works when there is no children
  customMobileBreakpoint?: number;
  disableOnMobile?: boolean;
  contentClassName?: string;
}

const Ellipsis = ({
  id = generateRandomKey(),
  title,
  className,
  children,
  hideIcon,
  maxCharacters,
  customMobileBreakpoint = MD_SCREEN_WIDTH,
  disableOnMobile,
  contentClassName,
}: IProps) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  const [isScreenMobile, setIsScreenMobile] = useState(false);

  const handleResize = useDebounce(() => {
    if (textRef.current) {
      setIsOverflown(textRef.current.scrollWidth > textRef.current.clientWidth);
      setIsScreenMobile(window.innerWidth < customMobileBreakpoint);
    }
  }, 500);

  useEffect(() => {
    if (textRef.current) {
      const element = textRef.current;
      setIsOverflown(element.scrollWidth > element.clientWidth);
      setIsScreenMobile(window.innerWidth < customMobileBreakpoint);
      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, customMobileBreakpoint]);

  const isLongText = maxCharacters ? title.length > maxCharacters : false; // This only works when there is no children

  const isMobileHidden = disableOnMobile && isScreenMobile;

  const generateContent = () => {
    if (children) {
      return children;
    }

    if (isMobileHidden) {
      return title;
    }

    return maxCharacters ? cutOffString(title, maxCharacters) : title;
  };

  return (
    <div
      data-tooltip-id={id}
      className={twMerge(
        'flex min-w-0 flex-grow items-center gap-1',
        (isOverflown || isLongText) && !isMobileHidden && 'cursor-pointer',
        className
      )}
    >
      <div
        ref={textRef}
        className={twMerge(
          'max-w-full overflow-hidden text-ellipsis whitespace-nowrap',
          contentClassName
        )}
      >
        {generateContent()}
      </div>
      {isScreenMobile && (isOverflown || isLongText) && !hideIcon && (
        <div tabIndex={0} role="button" onClick={(e) => e.stopPropagation()}>
          <InfoIcon className="size-3.5 cursor-pointer text-neutral500" />
        </div>
      )}
      {(isOverflown || isLongText) && !isMobileHidden && (
        <CustomTooltip id={id} title={title} />
      )}
    </div>
  );
};

export default Ellipsis;
