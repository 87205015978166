import { usePathname } from 'next/navigation';

import useCurrentUser from '@api/user/auth/queries/useCurrentUser';

import { FORUM, PUBLISHER } from '@shared/utils';

import { publisherForumApi } from './publisher';
import { userForumApi } from './user';

export const useSwitchForumApi = (isNotification?: boolean) => {
  const { data } = useCurrentUser();
  const pathname = usePathname();
  const isPublisher = pathname.includes(PUBLISHER);
  const isForum = pathname.includes(FORUM);
  if (isNotification) {
    if (!isPublisher && !isForum) return userForumApi;
    if (isPublisher) return publisherForumApi;
  }
  return data?.data ? userForumApi : publisherForumApi;
};
