import { publisherAiTrendsApi } from './publisherAiTrendsApi';
import { publisherCommentsApi } from './publisherCommentsApi';
import { publisherLikeStatusApi } from './publisherLikeStatusApi';
import { publisherNotificationsApi } from './publisherNotificationsApi';
import { publisherThreadsApi } from './publisherThreadsApi';

export * from '../queries/threads/useGetMyThreadsQuery';

export const publisherForumApi = {
  threads: publisherThreadsApi,
  notifications: publisherNotificationsApi,
  comments: publisherCommentsApi,
  likeStatus: publisherLikeStatusApi,
  aiTrends: publisherAiTrendsApi,
};
