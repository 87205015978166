import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useSwitchForumApi } from '@api/forum/useSwitchForumApi';

import { FORUM_KEYS } from '@shared/constants/queryKeys';
import { toastError } from '@shared/utils';

const useReadSingleNotificationMutation = () => {
  const queryClient = useQueryClient();

  const forumApi = useSwitchForumApi();

  return useMutation({
    mutationFn: (id: number) =>
      forumApi.notifications.client.readSingleNotification(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FORUM_KEYS.notifications.notificationsList],
      });
    },
    onError: (e) => toastError(e.message),
  });
};

export default useReadSingleNotificationMutation;
