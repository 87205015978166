import {
  IBasicResponse,
  IGetMyThreadsResponse,
  IMyThreadsParams,
  ISaveThreadResponse,
  ResponseWrapper,
} from '@interfaces';
import {
  ThreadDetailResponseData,
  ThreadListResponse,
} from '@interfaces/forum/thread';
import { IFetchParams } from '@interfaces/queryParams';

import { getPresignedUploadFile } from '@api/fileResources/fileResourcesApi';
import { withAuthenticatedClientRequest } from '@api/requestBuilder/client/withClientRequest';
import { appendParamsToUrl } from '@api/requestBuilder/helpers';
import { withAuthenticatedServerRequest } from '@api/requestBuilder/server/withServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import { ThreadFormData } from '@shared/schemas';

import { IReportThreadOrCommentBody } from '../mutations/useReportThreadOrCommentMutation';
import { userForumRoutes } from './userForumApi.routes';

// If there are no params, set default values
const parseParams = (params: IMyThreadsParams): IMyThreadsParams => {
  return {
    ...params,
    page: params.page || 1,
    limit: params.limit || 10,
  };
};

const getMyThreads =
  (request: ICallableRequestBuilder<IGetMyThreadsResponse>) =>
  async (params: IMyThreadsParams) => {
    const url = appendParamsToUrl(userForumRoutes.threads.myThreads, {
      ...parseParams(params),
    });
    return request.call(url);
  };

// If the threadId is sent then the thread is published
const savePublishThread =
  (request: ICallableRequestBuilder<ISaveThreadResponse>) =>
  async (data: ThreadFormData) => {
    return request.call(userForumRoutes.threads.save, (init) => ({
      ...init,
      method: 'POST',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }));
  };

const updateThread =
  (request: ICallableRequestBuilder<IBasicResponse>) =>
  async (data: ThreadFormData, threadId: number) => {
    return request.call(userForumRoutes.threads.update, (init) => ({
      ...init,
      method: 'PUT',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        threadId,
        ...data,
      }),
    }));
  };

const deleteThread =
  (request: ICallableRequestBuilder<IBasicResponse>) =>
  async (threadId: number) => {
    return request.call(userForumRoutes.threads.delete(threadId), (init) => ({
      ...init,
      method: 'DELETE',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
    }));
  };

const getThreadList =
  (request: ICallableRequestBuilder<ThreadListResponse>) =>
  async (params?: IFetchParams) => {
    const url = appendParamsToUrl(userForumRoutes.threads.threadList, params);
    return request.call(url);
  };

const getThreadById =
  (
    request: ICallableRequestBuilder<ResponseWrapper<ThreadDetailResponseData>>
  ) =>
  async (id: number) =>
    request.call(`${userForumRoutes.threads.thread}/${id}`);

const reportThread =
  (request: ICallableRequestBuilder<IBasicResponse>) =>
  async (data: IReportThreadOrCommentBody) => {
    return request.call(userForumRoutes.threads.report, (init) => ({
      ...init,
      method: 'POST',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }));
  };

export const userThreadsApi = {
  client: {
    getMyThreads: withAuthenticatedClientRequest(getMyThreads),
    savePublishThread: withAuthenticatedClientRequest(savePublishThread),
    updateThread: withAuthenticatedClientRequest(updateThread),
    deleteThread: withAuthenticatedClientRequest(deleteThread),
    getThreadList: withAuthenticatedClientRequest(getThreadList),
    getThreadById: withAuthenticatedClientRequest(getThreadById),
    reportThread: withAuthenticatedClientRequest(reportThread),
    getPresignedUploadFile: withAuthenticatedClientRequest(
      getPresignedUploadFile
    ),
  },
  server: {
    getMyThreads: withAuthenticatedServerRequest(getMyThreads),
    getThreadList: withAuthenticatedServerRequest(getThreadList),
    getThreadById: withAuthenticatedClientRequest(getThreadById),
  },
};
