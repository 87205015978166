import { twMerge } from 'tailwind-merge';

import { ForumWrenchIcon } from '@shared/assets';

import { LINKS_CONFIG } from '../constants';

interface IProps {
  className?: string;
}
const MustReadAndFeaturedLinks = ({ className }: IProps) => {
  return (
    <div
      className={twMerge(
        'flex flex-col border border-neutral700 bg-neutral800',
        className
      )}
    >
      {/*<div className="flex flex-col gap-4 border-b border-neutral700">
        <div className="flex gap-[10px] px-4 pt-4 text-neutral400">
          <ForumWrenchIcon />
          <span className="font-medium">Must-read</span>
        </div>
        <ul className="list-inside list-disc px-4 pb-4 font-medium">
          {LINKS_CONFIG.mustRead.map((link, index) => (
            <li key={index}>
              <a href={link.url} target="_blank">
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      </div>*/}
      <div className="flex flex-col gap-4">
        <div className="flex gap-[10px] px-4 pt-4 text-neutral400">
          <ForumWrenchIcon />
          <span className="font-medium">Featured links</span>
        </div>
        <ul className="list-inside list-disc px-4 pb-4 font-medium">
          {LINKS_CONFIG.featuredLinks.map((link, index) => (
            <li key={index} className="mb-4 last:mb-0">
              <a href={link.url} target="_blank">
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MustReadAndFeaturedLinks;
