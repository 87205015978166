import { PaginatedResponseWrapper, ResponseWrapper } from '@interfaces';
import {
  INotificationParams,
  INotifications,
} from '@interfaces/forum/notifications';

import { withAuthenticatedPublisherClientRequest } from '@api/requestBuilder/client/withAuthenticatedPublisherClientRequest';
import { withAuthenticatedPublisherServerRequest } from '@api/requestBuilder/server/withAuthenticatedPublisherServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import { generateUrlParams } from '@shared/utils/urlString';

import { publisherForumRoutes } from './publisherForumApi.routes';

const getNotificationsList =
  (
    request: ICallableRequestBuilder<PaginatedResponseWrapper<INotifications[]>>
  ) =>
  async (params: INotificationParams) => {
    const paramString = generateUrlParams(params);

    return await request.call(
      `${publisherForumRoutes.notifications.getNotificationsList}?${paramString}`
    );
  };

const hasUnreadNotifications =
  (request: ICallableRequestBuilder<ResponseWrapper>) => async () =>
    await request.call(
      `${publisherForumRoutes.notifications.hasUnreadNotifications}`
    );

const readAllNotifications =
  (request: ICallableRequestBuilder<ResponseWrapper>) => async () =>
    await request.call(
      `${publisherForumRoutes.notifications.readAllNotifications}`,
      (init) => ({
        ...init,
        method: 'PUT',
      })
    );

const readSingleNotification =
  (request: ICallableRequestBuilder<ResponseWrapper>) => async (id: number) =>
    await request.call(
      `${publisherForumRoutes.notifications.readSingleNotification}/${id}`,
      (init) => ({
        ...init,
        method: 'PUT',
      })
    );

export const publisherNotificationsApi = {
  client: {
    getNotificationsList:
      withAuthenticatedPublisherClientRequest(getNotificationsList),
    readAllNotifications:
      withAuthenticatedPublisherClientRequest(readAllNotifications),
    readSingleNotification: withAuthenticatedPublisherClientRequest(
      readSingleNotification
    ),
    hasUnreadNotifications: withAuthenticatedPublisherClientRequest(
      hasUnreadNotifications
    ),
  },
  server: {
    getNotificationsList:
      withAuthenticatedPublisherServerRequest(getNotificationsList),
    hasUnreadNotifications: withAuthenticatedPublisherServerRequest(
      hasUnreadNotifications
    ),
  },
};
