import { Dispatch, ReactNode, SetStateAction } from 'react';

import { twMerge } from 'tailwind-merge';

import DividerFullWidth from '@components/dividerFullWidth';

import MustReadAndFeaturedLinks from '@features/forum/layout/forumSidebar/MustReadAndFeaturedLinks';

import { ChevronRightIcon } from '@shared/assets';

interface IProps {
  children: ReactNode;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

const SubMenuSidebar = ({ setIsOpen, children, isOpen }: IProps) => {
  return (
    <div
      className={twMerge(
        'fixed top-16 z-99 h-dvh w-screen bg-neutral1000 transition-all duration-300',
        isOpen ? 'left-0' : 'left-full'
      )}
    >
      <div className="flex h-fullMinusHeader flex-col overflow-y-auto">
        <button
          className="flex items-center justify-start gap-2 px-4 py-6"
          onClick={() => setIsOpen(!isOpen)}
        >
          <ChevronRightIcon className="size-4 rotate-180 text-white" />
          <span className="font-medium text-neutral300">Back to main menu</span>
        </button>
        <DividerFullWidth />
        <div className="flex flex-col p-4">{children}</div>
        <MustReadAndFeaturedLinks className="m-4 mt-auto" />
      </div>
    </div>
  );
};

export default SubMenuSidebar;
