import {
  AuthResponse,
  ErrorResponse,
  LoginWithWalletData,
} from '@interfaces/auth';
import { useMutation } from '@tanstack/react-query';

import { userAuthApi } from '../../userAuthApi';

interface MutationParams {
  onSuccess: (response: AuthResponse) => void;
  onError: (error: ErrorResponse | Error) => void;
}

const useLoginWithWalletMutation = ({ onSuccess, onError }: MutationParams) => {
  return useMutation({
    mutationFn: async (data: LoginWithWalletData) =>
      userAuthApi.client.loginWithWallet(data),
    onSuccess,
    onError,
  });
};

export default useLoginWithWalletMutation;
