import { IGenericResponse } from '@interfaces';
import {
  IPresignedUrlRequest,
  IPresignedUrlResponse,
} from '@interfaces/fileResources';

import { withAuthenticatedPublisherClientRequest } from '@api/requestBuilder/client/withAuthenticatedPublisherClientRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import config from '@shared/config';

export const getPublisherPresignedUploadFile =
  (request: ICallableRequestBuilder<IGenericResponse<IPresignedUrlResponse>>) =>
  async (data: IPresignedUrlRequest) => {
    return request.call(
      `${config.publisherApiUrl}/api/v1/file-resources`,
      (init) => ({
        ...init,
        method: 'POST',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  };

export const getPublisherPresignedDownloadFile =
  (request: ICallableRequestBuilder<IGenericResponse<IPresignedUrlResponse>>) =>
  async (fileId: string) => {
    return request.call(
      `${config.publisherApiUrl}/api/v1/file-resources/${fileId}`,
      (init) => ({
        ...init,
        method: 'GET',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
      })
    );
  };

export const publisherFileResourcesApi = {
  client: {
    getPublisherPresignedUploadFile: withAuthenticatedPublisherClientRequest(
      getPublisherPresignedUploadFile
    ),
    getPublisherPresignedDownloadFile: withAuthenticatedPublisherClientRequest(
      getPublisherPresignedDownloadFile
    ),
  },
};
