'use client';

import { useMemo } from 'react';

import { IMyThreadsParams } from '@interfaces';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { useSwitchForumApi } from '@api/forum/useSwitchForumApi';

import { FORUM_KEYS } from '@shared/constants/queryKeys';

const DEFAULT_PAGE_NUMBER = 1;

const useGetMyThreadsQuery = (params: IMyThreadsParams) => {
  const forumApi = useSwitchForumApi();

  const {
    data,
    isFetchingNextPage,
    isFetching: isLoading,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [FORUM_KEYS.threads.myThreads, { ...params }],
    queryFn: ({ pageParam = 0 }) =>
      forumApi.threads.client.getMyThreads({
        ...params,
        page: pageParam,
      }),
    initialPageParam: DEFAULT_PAGE_NUMBER,
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage + 1;
      return nextPage > lastPage.meta.totalPages ? null : nextPage;
    },
    placeholderData: keepPreviousData,
  });

  const flattenedData = useMemo(
    () => data?.pages.flatMap((page) => page.data),
    [data]
  );

  const isEmptyContent =
    !isLoading && !isFetchingNextPage && !flattenedData?.length;

  return {
    data: flattenedData,
    refetch,
    fetchNextPage,
    hasNextPage,
    isEmptyContent,
    isLoading,
  };
};

export default useGetMyThreadsQuery;
