import { twMerge } from 'tailwind-merge';

import LoadingIcon from './LoadingIcon';

interface IProps {
  className?: string;
}

const Loading = ({ className }: IProps) => {
  return (
    <div
      className={twMerge(
        'mt-10 flex w-full flex-col items-center justify-center gap-6 border border-neutral700 bg-neutral800 p-10',
        className
      )}
    >
      <LoadingIcon />
      <p className="text-center font-medium text-white">
        It will take a few seconds...
      </p>
    </div>
  );
};

export default Loading;
