import config from '@shared/config';

const BASE_PUBLISHER_URL = `${config.publisherApiUrl}/api/v1/publisher`;
const BASE_PUBLISHER_FORUM_URL = `${BASE_PUBLISHER_URL}/forum`;
const BASE_THREAD_URL = `${BASE_PUBLISHER_FORUM_URL}/thread`;
const BASE_FORUM_URL = `${config.publisherApiUrl}/api/v1/forum`;

export const publisherForumRoutes = {
  threads: {
    threadList: `${BASE_PUBLISHER_FORUM_URL}/threads`,
    myThreads: `${BASE_PUBLISHER_FORUM_URL}/my-threads`,
    save: `${BASE_THREAD_URL}/save`,
    update: `${BASE_THREAD_URL}`,
    report: `${BASE_PUBLISHER_FORUM_URL}/report`,
    delete: (threadId: number) => `${BASE_THREAD_URL}/${threadId}`,
    thread: `${BASE_PUBLISHER_FORUM_URL}/thread`,
  },
  comments: {
    reportedComment: `${BASE_PUBLISHER_FORUM_URL}/reported-comment`,
    reports: `${BASE_PUBLISHER_FORUM_URL}/reports`,
    deleteComment: `${BASE_PUBLISHER_FORUM_URL}/my-threads`,
    deleteReport: `${BASE_PUBLISHER_FORUM_URL}/report`,
    createComment: `${BASE_PUBLISHER_FORUM_URL}/comment`,
    getComments: `${BASE_PUBLISHER_FORUM_URL}/comments`,
    likeComment: `${BASE_PUBLISHER_FORUM_URL}/like-status`,
    reply: `${BASE_PUBLISHER_FORUM_URL}/reply`,
    getReplies: `${BASE_PUBLISHER_FORUM_URL}/replies`,
  },
  notifications: {
    readSingleNotification: `${BASE_FORUM_URL}/notification/read`,
    readAllNotifications: `${BASE_FORUM_URL}/notification/read-all`,
    hasUnreadNotifications: `${BASE_FORUM_URL}/notification/check-status`,
    getNotificationsList: `${BASE_FORUM_URL}/notification`,
  },
  likeStatus: `${BASE_PUBLISHER_FORUM_URL}/like-status`,
  aiTrends: {
    trendingKeywords: `${BASE_FORUM_URL}/ai-trends/keywords`,
    trendingIdeas: `${BASE_FORUM_URL}/ai-trends/ideas`,
  },
};
