import { userCommentsApi } from './userCommentsApi';
import { userLikeStatusApi } from './userLikeStatusApi';
import { userNotificationsApi } from './userNotificationsApi';
import { userThreadsApi } from './userThreadsApi';

export const userForumApi = {
  threads: userThreadsApi,
  notifications: userNotificationsApi,
  comments: userCommentsApi,
  likeStatus: userLikeStatusApi,
};
