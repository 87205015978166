export const countCommasDotsDashes = (value: string) => {
  return (
    value.split(',').length +
    value.split('.').length +
    value.split('-').length -
    3
  );
};

export const sanitizeToFloat = (value: string): string => {
  const cleanedValue = value.replace(/[^\d.]/g, '');
  const [integerPart, ...decimalParts] = cleanedValue.split('.');

  return integerPart + (decimalParts.length ? '.' + decimalParts.join('') : '');
};
