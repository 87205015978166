import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { BREAKPOINT_CLASSES } from './constants';

interface IProps {
  children: ReactNode;
  isSmallNavigation?: boolean;
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
}

const DesktopMenu = ({
  children,
  isSmallNavigation,
  breakpoint = 'md',
}: IProps) => {
  return (
    <div
      className={twMerge(
        '-mb-px size-full items-center',
        isSmallNavigation
          ? `flex h-16 w-full justify-between overflow-hidden border-neutral700 ${BREAKPOINT_CLASSES.small[breakpoint]}`
          : `hidden ${BREAKPOINT_CLASSES.regular[breakpoint]}`
      )}
    >
      {children}
    </div>
  );
};

export default DesktopMenu;
