import { Dispatch, SetStateAction } from 'react';

import { UserAuthSteps } from '@interfaces/auth';
import { useAccount } from 'wagmi';

import { useWithCorrectNetwork } from '@web3/hooks';

import Modal from '@components/modals/Modal';
import ModalFooter from '@components/modals/ModalFooter';

import { MODAL_OPTIONS } from '@features/auth/constants';

import { CopyIcon } from '@shared/assets';
import { copyToClipboard } from '@shared/utils';

import useLoginWithWallet from '../hooks/useLoginWithWallet';

interface ILoginProps extends UserAuthSteps {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setActiveModal: Dispatch<SetStateAction<MODAL_OPTIONS>>;
  setRegistrationEmail: (registrationEmail: string) => void;
}

const Login = ({
  isModalOpen,
  handleModalClose,
  setIsModalOpen,
  setActiveModal,
  isLoading,
  setIsLoading,
  setRegistrationEmail,
}: ILoginProps) => {
  const { address } = useAccount();

  const loginWithWallet = useLoginWithWallet(
    setIsLoading,
    setIsModalOpen,
    setActiveModal,
    setRegistrationEmail
  );

  const login = useWithCorrectNetwork(loginWithWallet);

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={handleModalClose}
      disableClose={isLoading}
      modalTitle="Welcome to AITECH Marketplace"
      modalDescription="This request will not trigger a blockchain transaction or incur any fees"
      className="!max-w-25.5rem"
    >
      <div className="flex flex-col gap-6">
        <div className="font-xsmall flex w-full flex-col gap-2 border border-neutral500 px-2.5 py-3 font-bold max-xs:text-[0.625rem]">
          <div className="uppercase">Wallet address</div>
          <div className="flex justify-between">
            <div className="w-max max-w-full overflow-hidden overflow-ellipsis bg-neutral700 px-1.5 py-1 text-white max-xs:text-[0.525rem]">
              {address}
            </div>
            {!!address && (
              <button onClick={() => copyToClipboard(address)}>
                <CopyIcon className="size-3.5 text-neutral300" />
              </button>
            )}
          </div>
        </div>
      </div>
      <ModalFooter
        confirmButtonProps={{
          text: 'Login',
          onClick: login,
          isDisabled: isLoading,
        }}
      />
    </Modal>
  );
};

export default Login;
