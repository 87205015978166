import { AuthResponse, RegisterWitWalletData } from '@interfaces/auth';
import { useMutation } from '@tanstack/react-query';

import { userAuthApi } from '../../userAuthApi';

export interface MutationParams {
  onSuccess: (response: AuthResponse) => void;
  onError?: (error: Error) => void;
}

const useRegisterWithWalletMutation = ({
  onSuccess,
  onError,
}: MutationParams) => {
  return useMutation({
    mutationFn: async (data: RegisterWitWalletData) =>
      userAuthApi.client.registerWithWallet(data),
    onSuccess,
    onError,
  });
};

export default useRegisterWithWalletMutation;
