import { INavItem } from '@components/navigation/types';

import { FORUM_OVERVIEW, HOME, PUBLISHER_LISTINGS } from '@shared/utils';

export const NAVIGATION_ITEMS: INavItem[] = [
  {
    id: 'explorer',
    label: 'explorer',
    url: HOME,
  },
  {
    id: 'publisherPortal',
    label: 'publisher portal',
    url: PUBLISHER_LISTINGS,
  },
  {
    id: 'forum',
    label: 'solidus forum',
    url: FORUM_OVERVIEW,
    opensNewMenu: true,
  },
];
