import {
  IBasicResponse,
  IGetMyThreadsResponse,
  IMyThreadsParams,
  ISaveThreadResponse,
  ResponseWrapper,
} from '@interfaces';
import {
  ThreadDetailResponseData,
  ThreadListResponse,
} from '@interfaces/forum/thread';
import { IFetchParams } from '@interfaces/queryParams';

import { getPublisherPresignedUploadFile } from '@api/publisher/fileResources/publisherFileResources';
import { withAuthenticatedPublisherClientRequest } from '@api/requestBuilder/client/withAuthenticatedPublisherClientRequest';
import { appendParamsToUrl } from '@api/requestBuilder/helpers';
import { withAuthenticatedPublisherServerRequest } from '@api/requestBuilder/server/withAuthenticatedPublisherServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import { ThreadFormData } from '@shared/schemas';

import { IReportThreadOrCommentBody } from '../mutations/useReportThreadOrCommentMutation';
import { publisherForumRoutes } from './publisherForumApi.routes';

// If there are no params, set default values
const parseParams = (params: IMyThreadsParams): IMyThreadsParams => {
  return {
    ...params,
    page: params.page || 1,
    limit: params.limit || 10,
  };
};

const getMyThreads =
  (request: ICallableRequestBuilder<IGetMyThreadsResponse>) =>
  async (params: IMyThreadsParams) => {
    const url = appendParamsToUrl(publisherForumRoutes.threads.myThreads, {
      ...parseParams(params),
    });
    return request.call(url);
  };

const getThreadList =
  (request: ICallableRequestBuilder<ThreadListResponse>) =>
  async (params?: IFetchParams) => {
    const url = appendParamsToUrl(
      publisherForumRoutes.threads.threadList,
      params
    );
    return request.call(url);
  };

// If the threadId is sent then the thread is published
const savePublishThread =
  (request: ICallableRequestBuilder<ISaveThreadResponse>) =>
  async (data: ThreadFormData) => {
    return request.call(publisherForumRoutes.threads.save, (init) => ({
      ...init,
      method: 'POST',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }));
  };

const updateThread =
  (request: ICallableRequestBuilder<IBasicResponse>) =>
  async (data: ThreadFormData, threadId: number) => {
    return request.call(publisherForumRoutes.threads.update, (init) => ({
      ...init,
      method: 'PUT',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        threadId,
        ...data,
      }),
    }));
  };

const deleteThread =
  (request: ICallableRequestBuilder<IBasicResponse>) =>
  async (threadId: number) => {
    return request.call(
      publisherForumRoutes.threads.delete(threadId),
      (init) => ({
        ...init,
        method: 'DELETE',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
      })
    );
  };

const getThreadById =
  (
    request: ICallableRequestBuilder<ResponseWrapper<ThreadDetailResponseData>>
  ) =>
  async (id: number) =>
    request.call(`${publisherForumRoutes.threads.thread}/${id}`);

const reportThread =
  (request: ICallableRequestBuilder<IBasicResponse>) =>
  async (data: IReportThreadOrCommentBody) => {
    return request.call(publisherForumRoutes.threads.report, (init) => ({
      ...init,
      method: 'POST',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }));
  };

export const publisherThreadsApi = {
  client: {
    getMyThreads: withAuthenticatedPublisherClientRequest(getMyThreads),
    savePublishThread:
      withAuthenticatedPublisherClientRequest(savePublishThread),
    updateThread: withAuthenticatedPublisherClientRequest(updateThread),
    deleteThread: withAuthenticatedPublisherClientRequest(deleteThread),
    getThreadList: withAuthenticatedPublisherClientRequest(getThreadList),
    reportThread: withAuthenticatedPublisherClientRequest(reportThread),
    getThreadById: withAuthenticatedPublisherClientRequest(getThreadById),
    getPresignedUploadFile: withAuthenticatedPublisherClientRequest(
      getPublisherPresignedUploadFile
    ),
  },
  server: {
    getMyThreads: withAuthenticatedPublisherServerRequest(getMyThreads),
    getThreadList: withAuthenticatedPublisherServerRequest(getThreadList),
    getThreadById: withAuthenticatedPublisherServerRequest(getThreadById),
  },
};
